<template>
  <tr>
    <td class="px-2 py-3 d-table-cell">
      <router-link
        tag="a"
        :to="{ name: 'edit_extended_profiling', params: { id: data.id }}"
        class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"

      >
        {{ data.question | stripHTML }}
      </router-link>
    </td>
    <td class="px-2 py-3 d-table-cell text-center">
      <a
        href="javascript:void(0)"
        class="text-dark-65 text-hover-primary mb-1 font-size-sm"
      >
        {{ data.system_answer_type.name }}
      </a>
    </td>
    <td class="px-2 py-3 d-table-cell text-center">
      <b-badge
        :variant="calculateBool(data.display)[0]"
      >
        {{ calculateBool(data.display)[1] }}
      </b-badge>
    </td>
    <td class="px-2 py-3 d-table-cell text-center">
      <b-badge
        :variant="calculateBool(data.required)[0]"
      >
        {{ calculateBool(data.required)[1] }}
      </b-badge>
    </td>
    <td class="px-2 py-3 d-table-cell text-right" style="width:70px">
      <a
        href="javascript:void(0)"
        class="text-danger text-hover-danger font-size-sm hidden-delete"
        @click="deleteExtendedProfileQuestion(data)"
      >
        {{ $t('GENERAL.TEXT.DELETE') }}
      </a>
    </td>
  </tr>
</template>

<script>
import {
  DELETE_EXTENDED_PROFILE_QUESTION
} from "@/core/services/store/project/project_extended_profile_questions.module";

export default {
  name: "ExtendedProfileQuestionRow",

  props: ["data", "index", "project"],

  methods: {
    calculateBool(bool) {
      let returnArray = [];

      if(bool) {
        returnArray[0] = "success",
        returnArray[1] = "yes"
      } else {
        returnArray[0] = "danger",
        returnArray[1] = "no"
      }

      return returnArray;
    },
    deleteExtendedProfileQuestion(extendedProfileQuestion) {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.EP_DELETE_CHALLENGE.TITLE")}`,
        text: `${this.$t("SWEETALERTS.EP_DELETE_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.EP_DELETE_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            projectId: this.project.id,
            id: extendedProfileQuestion.id
          };
          this.$store
          .dispatch(DELETE_EXTENDED_PROFILE_QUESTION, payload)
          .then(() => {})
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.BLOCK_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })  
          })
        }
      })
    }
  }
};
</script>
