<template>
  <div>
    <div class="col-xxl-12 px-0">
      <div
        class="card card-custom card-stretch gutter-b"
        :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
      >
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div>
              <h6>{{ $t('SETUP.NAV.EXTENDED_PROFILING') }}</h6>
              <p class="mb-10">{{ $t('SETUP.EXTENDED_PROFILING.DESCRIPTION') }}</p>              
            </div>
            <div>
              <router-link
                to="add_extended_profiling"
                tag="a"
                class="btn btn-primary"
              >
                {{ $t('SETUP.EXTENDED_PROFILING.ADD') }}
              </router-link>
            </div>
          </div>

          <b-alert v-if="error.active" show variant="danger">
            {{ error.message }}
          </b-alert>

          <b-spinner
            v-if="loadingComplete == false && error.active == false"
            variant="primary"
            label="Spinning"
            class="card-loader"
          >
          </b-spinner>

          <div v-if="loadingComplete" class="table-responsive">
            <div class="col-xl-8 px-0">
              <div class="table-responsive">
                <table
                  id="extended_profiling_table"
                  class="table table-head-custom table-vertical center table-hover mt-5"
                  style="table-layout:auto"
                >
                  <thead>
                    <tr>
                      <th
                        class="px-2 py-3"
                        style="width:400px"
                      >
                        {{ $t('TABLES.EXTENDED_PROFILE_HEADERS.NAME') }}
                      </th>
                      <th
                        class="px-2 py-3 text-center"
                        style="width:200px"
                      >
                        {{ $t('TABLES.EXTENDED_PROFILE_HEADERS.TYPE') }}
                      </th>
                      <th
                        class="px-2 py-3 text-center"
                        style="width:160px"
                      >
                        {{ $t('TABLES.EXTENDED_PROFILE_HEADERS.DISPLAY') }}
                      </th>
                      <th
                        class="px-2 py-3 text-center"
                        style="width:160px"
                      >
                        {{ $t('TABLES.EXTENDED_PROFILE_HEADERS.REQUIRED') }}
                      </th>
                      <th
                        class="px-2 py-3 text-center"
                        style="width:160px"
                      >
                      </th>              
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(question, i) in extendedProfileQuestions">
                      <ExtendedProfileQuestionRow
                        :key="i"
                        :data="question"
                        :index="i"
                        :project="projectInfo"
                      >
                      </ExtendedProfileQuestionRow>
                    </template>
                    <tr v-if="extendedProfileQuestions.length == 0 && loadingComplete">
                      <td class="px-2 text-center" colspan="5">
                        <span class="text-dark-65 font-weight-bold d-block py-3">
                          {{ $t('TABLES.EXTENDED_PROFILE_MESSAGES.NO_EXTENDED_PROFILES_TEXT') }}
                          <router-link
                            tag="a"
                            class="text-primary"
                            :to="{ name: 'add_extended_profiling' }"
                          >
                            {{ $t('TABLES.EXTENDED_PROFILE_MESSAGES.NO_EXTENDED_PROFILES_LINK') }}
                          </router-link>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  POPULATE_EXTENDED_PROFILE_QUESTIONS
} from "@/core/services/store/project/project_extended_profile_questions.module";

import ExtendedProfileQuestionRow from "@/view/content/projects/ExtendedProfileQuestionRow.vue";

export default {
  name: "ExtendedProfiling",

  components: {
    ExtendedProfileQuestionRow
  },

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  mounted() {
    this.fetchExtendedProfileQuestions();
  },

  methods: {
    fetchExtendedProfileQuestions() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          systemAnswerType: 'with'
        }
      }

      this.$store
        .dispatch(POPULATE_EXTENDED_PROFILE_QUESTIONS, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the profile questions list, please check back later or contact the helpdesk";
        });
    },
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "extendedProfileQuestions"
    ]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>
